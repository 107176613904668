import { IconChevronRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";

const NavigationSubItem = (props) => {
    return (
        <div>
            <Link to={`/${props.slug}`}>
                <div className="highContrastDiv flex justify-between py-3 px-5 w-full lg:hover:bg-slate-100 dark:lg:hover:bg-gray-600 text-sm overflow-hidden hover:underline cursor-pointer lg:transition lg:duration-300">
                    <p className="truncate">{props.label}</p>
                    <IconChevronRight size="20" className="flex-shrink-0 lg:block hidden"/>
                </div>
            </Link>
        </div>
    );
}

export default NavigationSubItem;