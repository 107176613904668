import { useEffect, useState } from "react";
import Article from "../components/Article";
import { getPostsByPageNumber } from "../WordPressConnectionHelper";
import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";

const Archiwum = () => {
    const [postsList, setPostsList] = useState([]);
    const [postsPageNumber, setPostsPageNumber] = useState(1);
    const [maxPages, setMaxPages] = useState(null);

    const scrollTo = 400;

    const nextPage = () => {
        if (postsPageNumber < maxPages) {
            setPostsPageNumber(postsPageNumber + 1);
            window.scrollTo({ top: scrollTo, behavior: 'smooth' });
        }

    }

    const previousPage = () => {
        if (postsPageNumber > 1) {
            setPostsPageNumber(postsPageNumber - 1);
            window.scrollTo({ top: scrollTo, behavior: 'smooth' });
        }
    }

    const firstPage = () => {
        setPostsPageNumber(1);
        window.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }

    const lastPage = () => {
        setPostsPageNumber(maxPages);
        window.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }

    const getPosts = () => {
        getPostsByPageNumber(postsPageNumber)
            .then((data) => {
                setMaxPages(data[1]);
                if (data) {
                    setPostsList(data[0].map((single) => <Article key={single.id} id={single.id} title={single.title.rendered} mainImage={single.featured_media} creationDate={single.date} content={single.excerpt.rendered} />));
                }
                else {
                    setPostsList([]);
                }
            });
    }

    useEffect(() => {
        getPosts();
    }, [postsPageNumber]);

    return (
        <div>
            <div className="subpages-main-div">
                <div className="highContrastBackground custom-page">
                    <p className="subpages-p">Archiwum</p>
                    <div className="flex flex-col gap-5 items-center">
                        {postsList}
                    </div>
                    <div className="flex gap-1 items-center justify-center mt-5">
                        <IconChevronsLeft onClick={firstPage} size="30" className="p-1 border border-blue-200 hover:bg-blue-200 dark:hover:bg-blue-900 dark:border-blue-800 rounded-lg cursor-pointer transition" />
                        <IconChevronLeft onClick={previousPage} size="30" className="p-1 border border-blue-200 hover:bg-blue-200 dark:hover:bg-blue-900 dark:border-blue-800 rounded-lg cursor-pointer transition" />
                        <p className="font-semibold mx-3">{postsPageNumber}</p>
                        <IconChevronRight onClick={nextPage} size="30" className="p-1 border border-blue-200 hover:bg-blue-200 dark:hover:bg-blue-900 dark:border-blue-800 rounded-lg cursor-pointer transition" />
                        <IconChevronsRight onClick={lastPage} size="30" className="p-1 border border-blue-200 hover:bg-blue-200 dark:hover:bg-blue-900 dark:border-blue-800 rounded-lg cursor-pointer transition" />
                    </div>
                    {postsPageNumber === maxPages &&
                        <a href="https://old.zse.krakow.pl/index.php?page=archiwum" className="flex self-center mt-5">
                            <div className="flex items-center justify-center self-center text-sm gap-3 p-4 bg-slate-100 border border-slate-200 dark:bg-gray-800 dark:border-gray-700 w-fit rounded-lg cursor-pointer hover:bg-slate-200 hover:dark:bg-gray-700 transition duration-300">
                                <p>Zobacz artykuły z poprzednich lat</p>
                                <IconChevronRight size={20} />
                            </div>
                        </a>
                    }
                </div>
            </div>
        </div>
    );
}

export default Archiwum;