import { IconAlbum, IconFriends, IconHome, IconInfoCircle, IconUserCheck } from "@tabler/icons-react";
import NavigationItem from "./NavigationItem";
import { useEffect } from "react";
import { getFullNavigation } from "../WordPressConnectionHelper";
import { useState } from "react";
const Navigation = () => {
    const [nav, setNav] = useState(null);
    useEffect(() => {
        getFullNavigation().then((data) => {
            if(data) {
                setNav(data.items);
            }
        });
    }, []);
    return (
        <div className="flex justify-center mx-4 my-5 lg:m-0">
            <div className="highContrastDiv flex flex-col lg:flex-row lg:space-x-2 px-2 py-2 mb-2 mx-1 bg-white/90 dark:bg-gray-800/90 dark:text-white backdrop-blur-md w-full lg:w-fit space-y-1 lg:space-y-0 shadow-xl rounded-lg justify-center"
                id="hamburgerMenu">
                    <NavigationItem icon={<IconHome/>} label="Szkoła" items={nav && nav[0] && nav[0].child_items} index="0" />
                    <NavigationItem icon={<IconFriends/>} label="Dla uczniów" items={nav && nav[1] && nav[1].child_items} index="1"/>
                    <NavigationItem icon={<IconUserCheck/>} label="Dla kandydatów" items={nav && nav[2] && nav[2].child_items} index="2"/>
                    <NavigationItem icon={<IconInfoCircle/>} label="Informacje" items={nav && nav[3] && nav[3].child_items} index="3"/>
                    <NavigationItem icon={<IconAlbum/>} label="Galeria" items={nav && nav[4] && nav[4].child_items} index="4"/>
            </div>
        </div>
    );
}

export default Navigation;