const Footer = () => {
    return (
        <div className="w-full p-3">
            <footer className="highContrastBackground dark:bg-gray-800 bg-slate-100 shadow-inner rounded-xl dark:text-white p-5">
                <div className="flex justify-between items-center text-left">
                    <div className="space-y-3 mr-7">
                        <p>&copy; 2025 Zespół Szkół Energetycznych</p>
                        <p className="text-sm">Made with ❤️ by absolwenci szkoły: <a href="https://github.com/jakub-sieradzki">Jakub Sieradzki</a> and <a href="https://github.com/dawid-poradzinski">Dawid Poradziński</a></p>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;