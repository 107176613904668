import { IconChevronRight } from "@tabler/icons-react";

const Strony = () => {
    return (
        <div className="subpages-main-div">
            <div className="highContrastBackground custom-page">
                <p className="subpages-p">Nasze strony</p>
                <div className="flex flex-col space-y-4">
                    <a href="https://www.bip.krakow.pl/?bip_id=264">
                        <div className="link-main-div bg-slate-100 dark:bg-gray-800 hover:bg-slate-200 hover:dark:bg-gray-800/50">
                            <div className="flex items-center space-x-4">
                                <img src="/assets/bip-white.png" alt="BIP" className="h-9" />
                                <p className="text-lg font-semibold lg:hidden">BIP</p>
                                <p className="hidden lg:block text-lg font-semibold">Biuletyn Informacji Publicznej</p>
                            </div>
                            <div className="flex space-x-2">
                                <p>Przejdź</p>
                                <IconChevronRight />
                            </div>
                        </div>
                    </a>
                    <a href="https://www.facebook.com/ZSEwKrakowie">
                        <div className="link-main-div bg-slate-100 dark:bg-gray-800 hover:bg-slate-200 hover:dark:bg-gray-800/50">
                            <div className="flex items-center space-x-4">
                                <img src="/assets/facebook.png" alt="FB" className="h-9" />
                                <p className="text-lg font-semibold">Facebook</p>
                            </div>
                            <div className="flex space-x-2">
                                <p>Przejdź</p>
                                <IconChevronRight />
                            </div>
                        </div>
                    </a>
                    <a href="http://jubileusz.zse.krakow.pl/">
                        <div className="link-main-div bg-slate-100 dark:bg-gray-800 hover:bg-slate-200 hover:dark:bg-gray-800/50">
                            <div className="flex items-center space-x-4">
                                <img src="/assets/70.png" alt="70" className="h-9" />
                                <p className="text-lg font-semibold">Jubileusz</p>
                            </div>
                            <div className="flex space-x-2">
                                <p>Przejdź</p>
                                <IconChevronRight />
                            </div>
                        </div>
                    </a>
                    <a href="https://www.youtube.com/user/ZSEwKrakowie">
                        <div className="link-main-div bg-slate-100 dark:bg-gray-800 hover:bg-slate-200 hover:dark:bg-gray-800/50">
                            <div className="flex items-center space-x-4">
                                <img src="/assets/yt.png" alt="70" className="w-9" />
                                <p className="text-lg font-semibold">YouTube</p>
                            </div>
                            <div className="flex space-x-2">
                                <p>Przejdź</p>
                                <IconChevronRight />
                            </div>
                        </div>
                    </a>
                    <a href="https://moodle.zse.krakow.pl/">
                        <div className="link-main-div bg-slate-100 dark:bg-gray-800 hover:bg-slate-200 hover:dark:bg-gray-800/50">
                            <div className="flex items-center space-x-4">
                                <img src="/assets/moodle.png" alt="Moodle" className="w-9" />
                                <p className="text-lg font-semibold">Moodle</p>
                            </div>
                            <div className="flex space-x-2">
                                <p>Przejdź</p>
                                <IconChevronRight />
                            </div>
                        </div>
                    </a>
                    <a href="http://erasmus.zse.krakow.pl/">
                        <div className="link-main-div bg-slate-100 dark:bg-gray-800 hover:bg-slate-200 hover:dark:bg-gray-800/50">
                            <div className="flex items-center space-x-4">
                                <img src="/assets/ue.png" alt="Moodle" className="w-9" />
                                <p className="text-lg font-semibold">Erasmus+</p>
                            </div>
                            <div className="flex space-x-2">
                                <p>Przejdź</p>
                                <IconChevronRight />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Strony;