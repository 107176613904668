import { IconChevronRight, IconSectionSign } from "@tabler/icons-react";
const DocsItem = (props) => {
    return (
        <div>
            <a className="w-full" href={props.link}>
                <div className="w-full link-main-div gap-5 bg-slate-100 dark:bg-gray-800 hover:bg-slate-200 hover:dark:bg-gray-800/50">
                    <div className="flex items-center space-x-4">
                        <IconSectionSign className="flex-shrink-0"/>
                        <p className="text-lg font-semibold line-clamp-2">{props.title}</p>
                    </div>
                    <div className="flex space-x-2">
                        <p>Otwórz</p>
                        <IconChevronRight />
                    </div>
                </div>
            </a>
        </div>
    );
}

export default DocsItem;