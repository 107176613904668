
import { useEffect, useState } from "react";
import { getAllMediaFromPage, getPage } from "../WordPressConnectionHelper";
import DocsItem from "../components/DocsItem";

const Dokumenty = () => {
    const [docsItems, setDocsItems] = useState('');

    useEffect(() => {
        getPage("dokumenty-szkolne").then((data) => {
            getAllMediaFromPage(data[0].id)
                .then((docs) => {
                    setDocsItems(docs.map((doc) => {
                        return <DocsItem key={doc.id} title={doc.title.rendered} link={doc.source_url} />
                    }));
                })
        });
    }, []);


    return (
        <div className="subpages-main-div">
            <div className="highContrastBackground custom-page">
                <p className="subpages-p">Dokumenty szkolne</p>
                <div className="flex justify-center flex-col gap-4">
                    {docsItems}
                </div>
            </div>
        </div>
    );
}

export default Dokumenty;